import { useState, type FormEvent } from "react"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import captureException from "@utils/captureException"

import Container from "@components/Container"
import SpacingContainer from "@components/SpacingContainer"
import apiRequest from "@utils/apiRequest"
import TextInput from "@components/TextInput"
import Button from "@components/Button"
import { $auth } from "@stores/auth"
import { useStore } from "@nanostores/react"

export interface CreatorSignupFormProps extends Domain.Shared.DataTransferObjects.BlockBaseConfigData {
  background_color: Domain.Shared.Enums.ColorPaletteEnum
  cta_copy: string
}

const CreatorSignupForm = (props: CreatorSignupFormProps): JSX.Element => {
  const { margin_top, margin_bottom, padding_top, padding_bottom, background_color } = props
  const spacingMarginTop = margin_top?.value ?? "none"
  const spacingMarginBottom = margin_bottom?.value ?? "none"
  const spacingPaddingTop = padding_top?.value ?? "none"
  const spacingPaddingBottom = padding_bottom?.value ?? "none"

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation<string>("common")

  const handleSubmit = async (e: FormEvent) => {
    setIsLoading(true)
    e.preventDefault()
    try {
      await apiRequest("/v1/lists/partner", {
        method: "POST",
        body: { firstName, lastName, email },
      })
      setIsSuccess(true)
    } catch (error: any) {
      setIsSuccess(false)
      setHasError(true)
      captureException(error)
    }
  }

  const { user } = useStore($auth)
  const isHidden = (props.hide_for_logged_in_users && user) || (props.hide_for_logged_out_users && !user)

  if (isHidden) return <></>

  return (
    <SpacingContainer
      margin_top={spacingMarginTop}
      margin_bottom={spacingMarginBottom}
      padding_top={spacingPaddingTop}
      padding_bottom={spacingPaddingBottom}
      background_color={background_color}
      class_name="overflow-hidden"
    >
      <Container>
        <div className="md:grid md:grid-cols-12 md:gap-16">
          <div className="col-span-4 col-start-5">
            {hasError && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="error"
                className="pb-56 text-center"
              >
                <p className="text-30">{t("creators.alreadyExistsMessage")}</p>
              </motion.div>
            )}
            {isSuccess && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="success"
                className="pb-56 text-center"
              >
                <p className="text-30" dangerouslySetInnerHTML={{ __html: t("creators.successMessage") ?? "" }} />
              </motion.div>
            )}

            {!isSuccess && !hasError && (
              <form onSubmit={handleSubmit}>
                <div className="mb-16 grid grid-cols-2 gap-16">
                  <TextInput
                    id="firstName"
                    value={firstName}
                    onChange={setFirstName}
                    type="text"
                    label={t("creators.firstNameLabel")}
                    version="COMPACT"
                    required
                  />
                  <TextInput
                    id="lastName"
                    value={lastName}
                    onChange={setLastName}
                    type="text"
                    label={t("creators.lastNameLabel")}
                    version="COMPACT"
                    required
                  />
                </div>
                <div className="mb-16">
                  <TextInput
                    id="email"
                    type="email"
                    value={email}
                    onChange={setEmail}
                    label={t("creators.emailLabel")}
                    version="COMPACT"
                    required
                  />
                </div>
                <Button type="submit" version="SPINACH" size="LARGE" is_block is_loading={isLoading}>
                  {props.cta_copy}
                </Button>
              </form>
            )}
          </div>
        </div>
      </Container>
    </SpacingContainer>
  )
}

export default CreatorSignupForm
